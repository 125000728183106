/*
 *Dark Gray: D9D9D9
 *Light Gray: EDEDED
 *Purple: 762DD2
 *White: FFFFFF
 *Light Purple: 762DD2 (61%)
*/

h1 {
  color: #762dd2;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 24px;
}

h2 {
  color: #762dd2;
  padding: 10px;
  margin: 0;
  border-bottom: black solid 1px;
  font-size: 16px;
}

h3 {
  padding: 5px;
  margin: 5px;
}

h4 {
  padding: 5px;
  margin: 5px;
}

h5 {
  padding: 5px;
  margin: 5px;
}

.app {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: 100%;
  font-family: Roboto, tahoma, arial;
}

.button-menu {
  padding: 5px 10px 5px 10px;
  color: #762dd2;
  font-weight: bold;
}

.button-menu:hover {
  cursor: pointer;
  background-color: #762dd2;
  color: white;
  border-radius: 5px;
}

.button-primary {
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  background-color: #762dd2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  margin: 10px;
}

.button-primary:hover {
  cursor: pointer;
  background-color: white;
  color: #762dd2;
}

.button-primary:active {
  border-style: none;
}

.button-secondary {
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  background-color: #762dd2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  opacity: 0.61;
  text-align: center;
}

.button-secondary:hover {
  cursor: pointer;
  background-color: white;
  color: #762dd2;
}

.button-link {
  cursor: pointer;
  text-decoration: underline;
  color: #762dd2;
  font-size: 14px;
  padding: 5px;
  margin: 5px;
}

.button-link:hover {
  color: black;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: #d9d9d9;
  grid-column: 1 / span 2;
  padding: 10px 0;
  align-items: center;
}

.module-header {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / span 2;
  padding: 10px;
  align-items: center;
}

.side-menu {
  display: flex;
  flex-direction: column;
}

.side-menu-header {
  border-bottom: black solid 1px;
  border-right: black solid 1px;
  background-color: #ededed;
  padding: 0;
  font-size: 18px;
  padding: 10px;
  font-size: 16px;
}

.side-menu-item {
  border-bottom: black solid 1px;
  border-right: black solid 1px;
  border-left: black solid 1px;
  background-color: #ededed;
  padding: 0;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

.side-menu-item:hover {
  color: white;
  background-color: #762dd2;
}

.main-app {
  display: flex;
  justify-content: center;
}

.main-module {
  display: grid;

  min-width: 100%;
  grid-template-columns: 25% auto;
}

.module-main {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
}

.earlyArrival-group {
  padding: 10px;
}

.earlyArrival-list {
  margin: 10px;
  padding: 10px;
}

.main-dashboard {
  display: grid;
  padding: 10px;
}

.dashboard {
  padding: 10px;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login {
  background-color: #d9d9d9;
  border-radius: 25px;
  box-shadow: #762dd2 5px 5px;
  display: grid;
  width: 50%;
  height: 50%;
  min-height: 450px;
  min-width: 450px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
}

input,
select,
textarea {
  font-family: Roboto, tahoma, arial;
  padding: 10px;
  border: grey solid 2px;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px;
  resize: none;
}

.input-wide {
  width: 75%;
}

.text-long {
  width: 75%;
}

img {
  padding: 10px;
}

.login h2 {
  border: 0;
}

button {
  all: unset;
}

.button-primary:disabled,
.button-secondary:disabled {
  background-color: grey;
  color: black;
  border-style: none;
  cursor: default;
}

.login form {
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  min-width: 16em;
  padding: 15px;
}

.action-menu {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin: 10px;
}

.panel-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-body {
  border: 1px solid black;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 400px;
  max-width: 100%;
}

.panel-primary {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  margin: 10px;
  background-color: #d9d9d9;
  border-radius: 25px;
  max-height: fit-content;
}

.panel-primary-item {
  display: grid;
  grid-template-columns: 25% 75%;
  text-align: left;
  padding: 10px;
  margin: 10px;
}

.label {
  font-weight: bold;
  align-self: center;
  font-size: 14px;
}

.group-members {
  display: grid;
  padding: 10px;
  margin: 10px;
  background-color: #ededed;
  border-radius: 5px;
}

.add-to-group {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  background-color: #ededed;
  border-radius: 5px;
}

.group-members-heading {
  font-weight: bold;
  font-size: 16px;
  padding: 10px 5px;
  border-bottom: black solid 1px;
}

.group-members-row {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.group-members-item {
  padding: 10px 5px;
  align-self: center;
  font-size: 14px;
}

.group-members-action {
  padding: 10px 5px;
  align-self: center;
  font-size: 14px;
}

.group-members-action:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #762dd2;
}

span:hover {
  cursor: default;
}

.dropdown-menu {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  margin-bottom: 0;
  border: black solid 1px;
  background-color: white;
  font-size: 14px;
}

.dropdown-menu:hover {
  cursor: pointer;
}

.dropdown-drawer {
  position: absolute;
  border: black solid 1px;
  background-color: white;
  margin: 10px;
  padding: 10px;
  margin-top: 0;
  font-size: 14px;
}

.dropdown-drawer-item {
  position: relative;
  margin: 5px;
  padding: 5px;
  font-size: 14px;
}

.dropdown-drawer-item:hover {
  cursor: pointer;
  background-color: #ededed;
}

.wrapper {
  padding: 0;
  margin: 0;
  border: none;
}

.text {
  font-size: 14px;
  padding: 5px;
  margin: 5px;
}

.text-error {
  font-size: 14px;
  padding: 5px;
  margin: 5px;
  color: red;
}

.panel {
  padding: 10px;
  margin: 10px;
}

.panel-primary {
  background-color: #d9d9d9;
}

.popup {
  position: absolute;
  opacity: 100%;
  top: 50%;
  left: 50%;
  margin-top: -25vh;
  margin-left: -25vh;
  z-index: 100;
  text-align: center;
  border: 1px solid #7c72a0;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 10px #7c72a0;
  padding: 20px;
  min-width: 50vh;
  max-width: 50vh;
  background-color: white;
}

.popup-background {
  position: absolute;
  z-index: 98;
  min-height: 100vh;
  min-width: 100vw;
  background-color: lightgrey;
  opacity: 50%;
}

table {
  width: 100%;
  text-align: left;
}

.row {
  display: flex;
  align-items: center;
}

.system-banner {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  background-color: goldenrod;
  font-weight: bold;
}

.errorpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.errorpage h2 {
  border-bottom: 0;
}

.new-side-menu-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999998;
  background-color: white;
  opacity: 50%;
}

.new-side-menu {
  position: absolute;
  z-index: 9999999;
  /*background-color: #ededed;*/
  background-color: darkgray;
  top: 0;
  left: 0;
  padding: 5px;
  width: 25%;
  height: 98vh;
}

.new-side-menu-item {
  display: flex;
  width: 75%;
  margin: 10px;
  padding: 10px;
  padding-left: 15%;
  color: #762dd2;
  font-weight: bold;
}

.new-side-menu-item:hover {
  display: flex;
  text-decoration: underline;
  cursor: pointer;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.new-side-menu-header {
  margin: 10px;
  padding: 10px;
}

.fa-bars {
  cursor: pointer;
}

.fa-times {
  cursor: pointer;
}

.italicize {
  font-style: italic;
}

.fa-solid {
  margin-right: 10px;
  cursor: pointer;
}
